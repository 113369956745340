
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    useGetActivityHistoryByUserQuery
} from '../../redux/features/events/eventsApiSlice';
import { useSelector, useDispatch } from "react-redux";
import { selectPersonalData } from '../../redux/features/user/userSlice';
import FeedbackDialog from './FeedbackDialog/FeedbackDialog';
import EvaluateCounterpartsDialog from './FeedbackDialog/EvaluateCounterpartsDialog';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import GenericCardList, { ActionButtonProps } from '../../components/GenericCard/GenericCardList';
import { Actividad, ROLES } from './FeedbackQuestions/constants';

interface RoleInActivity {
    availableTimeId: number[];
    activityId: number;
    userId: number;
    fullName: string;
    role: 'Mentor' | 'Mentee';
    statusId: number | null;
    statusName: string;
    hasFeedback: { availableTimeId: number; userId: number; value: boolean; }[];
}

interface AccreditationByBlock {
    availableTimeId: number[];
    activityId: number;
    userId: number;
    fullName: string;
    statusId: number | null;
    statusName: string;
    hasFeedback: { availableTimeId: number; userId: number; value: boolean; }[];
    counterpart: boolean;
}

interface Activity {
    availableTimeId: number[];
    activityId: number;
    typeId: number;
    statusId: number | null;
    statusName: string;
    startDate: string;
    endDate: string;
    eventName: string;
    activityName: string;
    activityType: Actividad;
    activityDescription: string;
    roleInActivity: RoleInActivity;
    mentorAccreditationByBlock: AccreditationByBlock[];
    menteeAccreditationByBlock: AccreditationByBlock[];
}

interface ActivityHistoryProps { }

interface EvaluateCounterpart {
    name: string;
    hasFeedback: boolean;
    isAccredited: boolean;
    id: number;
    availableTimeId: number;
}

const ActivityHistory: React.FC<ActivityHistoryProps> = () => {
    const dispatch = useDispatch();
    const user_personal_data = useSelector(selectPersonalData);
    const user_id = user_personal_data.Id;
    const userEmail = user_personal_data.Email;

    const { data: activitiesData = { activities: [] }, refetch, isLoading, error } = useGetActivityHistoryByUserQuery(user_id);
    const activities: Activity[] = Array.isArray(activitiesData)
        ? activitiesData
        : (activitiesData?.activities ?? []);

    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
    const [selectedActivityId, setSelectedActivityId] = useState<number | null>(null);
    const [isMentor, setIsMentor] = useState(false);
    const [actividad, setActividad] = useState<Actividad | null>(null);
    const [contrapartes, setContrapartes] = useState<string[]>([]);
    const [openEvaluateCounterpartsDialog, setOpenEvaluateCounterpartsDialog] = useState(false);
    const [currentCounterpart, setCurrentCounterpart] = useState<string>("");
    const [counterpartIds, setCounterpartIds] = useState<number[]>([]);
    const [counterpartsListState, setCounterpartsListState] = useState<EvaluateCounterpart[]>([]);
    const [availableTimeIds, setAvailableTimeIds] = useState<number[]>([]);

    const statusNames: Record<number, string> = {
        1: "Acreditado",
        2: "Cancelado",
        3: "Pendiente",
        4: "Acreditado",
        5: "Evaluado"
    };

    useEffect(() => {
        refetch();
    }, [refetch]);

    const handleEvaluation = (
        activityId: number,
        counterpartsList: EvaluateCounterpart[],
        isMentorRole: boolean,
        actividadType: Actividad
    ) => {
        setSelectedActivityId(activityId);
        setIsMentor(isMentorRole);
        setActividad(actividadType);
        setContrapartes(counterpartsList.map(cp => cp.name));

        const counterpartIds = counterpartsList.map(cp => cp.id);
        setCounterpartIds(counterpartIds);

        const availableTimeIds = counterpartsList.map(cp => cp.availableTimeId).filter(id => id !== undefined);
        setAvailableTimeIds(availableTimeIds as number[]);

        setCounterpartsListState(counterpartsList);

        if (actividadType === 'Rueda de Contactos') {
            setOpenEvaluateCounterpartsDialog(true);
        } else {
            if (counterpartsList.length > 0) {
                setCurrentCounterpart(counterpartsList[0].name);
                setOpenFeedbackDialog(true);
            }
        }
    };

    const generateActionButtons = (activity: Activity): ActionButtonProps<any>[] => {
        const actionButtons: ActionButtonProps<any>[] = [];
        const { roleInActivity, typeId, mentorAccreditationByBlock, menteeAccreditationByBlock } = activity;
        const isRuedaDeContactos = typeId === 6;

        const userAcreditado = roleInActivity.statusId === 1;

        if (!userAcreditado) {
            return actionButtons;
        }

        if (isRuedaDeContactos) {
            if (roleInActivity.role !== 'Mentor') {
                return actionButtons;
            }

            const counterpartsList = menteeAccreditationByBlock.map(cp => {
                const hasFeedbackEntry = roleInActivity.hasFeedback.find(fb => fb.userId === cp.userId);
                const hasFeedback = hasFeedbackEntry ? hasFeedbackEntry.value : false;
                return {
                    name: cp.fullName,
                    hasFeedback: hasFeedback,
                    id: cp.userId,
                    isAccredited: cp.statusId === 1,
                    availableTimeId: cp.availableTimeId[0] || activity.availableTimeId[0],
                };
            });

            const hasAccreditedCounterpart = counterpartsList.some(cp => cp.isAccredited);

            const allAccreditedHaveFeedback = counterpartsList
                .every(cp => cp.hasFeedback);

            if (hasAccreditedCounterpart) {
                if (allAccreditedHaveFeedback) {
                    actionButtons.push({
                        label: 'Evaluado',
                        color: 'success',
                        onClick: () => { },
                        disabled: true,
                    });
                } else {
                    actionButtons.push({
                        label: 'Evaluar',
                        color: 'primary',
                        onClick: () => handleEvaluation(
                            activity.activityId,
                            counterpartsList,
                            roleInActivity.role === 'Mentor',
                            activity.activityType
                        ),
                        disabled: false,
                    });
                }
            } else {
                actionButtons.push({
                    label: 'Evaluar',
                    color: 'primary',
                    onClick: () => { },
                    disabled: true,
                });
            }
        } else {

            const counterpartAccreditationList = roleInActivity.role === 'Mentor' ? menteeAccreditationByBlock : mentorAccreditationByBlock;

            const counterpart = counterpartAccreditationList.find(cp => cp.statusId === 1);

            if (counterpart) {
                const hasFeedbackEntry = roleInActivity.hasFeedback.find(fb => fb.userId === counterpart.userId);
                const hasFeedback = hasFeedbackEntry ? hasFeedbackEntry.value : false;

                if (!hasFeedback) {
                    actionButtons.push({
                        label: 'Evaluar',
                        color: 'primary',
                        onClick: () => handleEvaluation(
                            activity.activityId,
                            [{
                                name: counterpart.fullName || "la contraparte",
                                hasFeedback: hasFeedback,
                                id: counterpart.userId || 0,
                                isAccredited: counterpart.statusId === 1,
                                availableTimeId: hasFeedbackEntry?.availableTimeId || activity.availableTimeId[0],
                            }],
                            roleInActivity.role === 'Mentor',
                            activity.activityType
                        ),
                        disabled: false,
                    });
                } else {
                    actionButtons.push({
                        label: 'Evaluado',
                        color: 'success',
                        onClick: () => { },
                        disabled: true,
                    });
                }
            } else {
                actionButtons.push({
                    label: 'Evaluar',
                    color: 'primary',
                    onClick: () => { },
                    disabled: true,
                });
            }
        }

        return actionButtons;
    };

    const formattedActivities = activities
        .filter((activity) => activity.activityId !== null)
        .map((activity) => {
            const isMentorRole = activity.roleInActivity.role === 'Mentor';
            const counterpart = isMentorRole ? activity.menteeAccreditationByBlock : activity.mentorAccreditationByBlock;

            return {
                Evento: activity.eventName,
                Temática: activity.activityType,
                Actividad: activity.activityName,
                Fecha: moment(activity.startDate).format('DD/MM/YYYY'),
                "Hora de Inicio": moment(activity.startDate).format('HH:mm'),
                "Hora de Termino": moment(activity.endDate).format('HH:mm'),
                Contraparte: counterpart.map((user) => ({
                    id: user.userId,
                    name: user.fullName,
                    hasFeedback: Array.isArray(user.hasFeedback) ? user.hasFeedback.some(fb => fb.value) : false,
                    isAccredited: user.statusId === 1
                })),
                Estado: activity.roleInActivity.statusId !== null ? (statusNames[activity.roleInActivity.statusId] || activity.roleInActivity.statusName) : activity.roleInActivity.statusName,
                Rol: activity.roleInActivity.role === 'Mentor' ? 'Experto' : 'Emprendedor',
                actionButtons: generateActionButtons(activity),
            };
        });

    const columnsToShow = [
        'Evento',
        'Actividad',
        'Temática',
        'Rol',
        'Fecha',
        'Hora de Inicio',
        'Hora de Termino',
        'Contraparte',
        'Estado',
    ];

    return isLoading ? (
        <div className='table-container-loading'>
            <p>Cargando actividades...</p>
        </div>
    ) : error ? (
        <div className='table-container'>
            <p>No hay actividades disponibles.</p>
        </div>
    ) : (
        <div className='card-container' style={{ width: '100%', paddingBottom: '80px', minHeight: '500px' }}>
            <GenericCardList
                columns={columnsToShow}
                data={formattedActivities}
                renderers={{
                    Contraparte: (users: { id?: number, name?: string, hasFeedback?: boolean, isAccredited?: boolean }[]) => (
                        <ul>
                            {users.map((user, index) =>
                                user.id && user.name ? (
                                    <li key={index}>
                                        <a
                                            href={`/user-profile/${user.id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {user.name}
                                        </a>

                                    </li>
                                ) : (
                                    <li key={index}>
                                        <span>Información de usuario no disponible</span>
                                    </li>
                                )
                            )}
                        </ul>
                    )
                }}
            />
            <FeedbackDialog
                open={openFeedbackDialog}
                onClose={() => setOpenFeedbackDialog(false)}
                counterpartName={currentCounterpart}
                isMentor={isMentor}
                actividad={actividad as Actividad}
                contrapartes={[currentCounterpart]}
                availableTimeIds={availableTimeIds}
                counterpartIds={counterpartIds}
                refetch={refetch}
            />
            {actividad === 'Rueda de Contactos' && (
                <EvaluateCounterpartsDialog
                    open={openEvaluateCounterpartsDialog}
                    onClose={() => setOpenEvaluateCounterpartsDialog(false)}
                    counterparts={counterpartsListState.map(cp => ({
                        name: cp.name,
                        hasFeedback: cp.hasFeedback,
                        isAccredited: cp.isAccredited,
                        id: cp.id
                    }))}
                    onEvaluate={(name: string, id: number) => {
                        const selectedCp = counterpartsListState.find(cp => cp.id === id);
                        setCurrentCounterpart(name);
                        setCounterpartIds([id]);
                        setAvailableTimeIds([selectedCp?.availableTimeId || 0]);
                        setOpenEvaluateCounterpartsDialog(false);
                        setOpenFeedbackDialog(true);
                    }}
                />
            )}
        </div>
    );
};

export default ActivityHistory;
