
import React, { useState, useEffect } from 'react';
import GenericCardList, { ActionButtonProps } from '../../components/GenericCard/GenericCardList';
import { useSelector, useDispatch } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import moment from 'moment';
import FeedbackDialog from './FeedbackDialog/FeedbackDialog';
import EvaluateCounterpartsDialog from './FeedbackDialog/EvaluateCounterpartsDialog';
import ConfirmationDialog from './ConfirmationDialog';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import {
    useGetUpcomingActivitiesByUserQuery,
    useCancelUpcomingActivityByUserMutation,
    useCancelMenteeContactWheelMutation,
    useCancelMentorContactWheelMutation
} from '../../redux/features/events/eventsApiSlice';
import { Actividad, ROLES } from './FeedbackQuestions/constants';

interface RoleInActivity {
    availableTimeId: number[];
    activityId: number;
    userId: number;
    fullName: string;
    role: 'Mentor' | 'Mentee';
    statusId: number | null;
    statusName: string;
    hasFeedback: { availableTimeId: number; userId: number; value: boolean; }[];
}

interface AccreditationByBlock {
    availableTimeId: number[];
    activityId: number;
    userId: number;
    fullName: string;
    statusId: number | null;
    statusName: string;
    hasFeedback: { availableTimeId: number; userId: number; value: boolean; }[];
    counterpart: boolean;
}

interface Activity {
    availableTimeId: number[];
    activityId: number;
    typeId: number;
    statusId: number | null;
    statusName: string;
    startDate: string;
    endDate: string;
    eventName: string;
    activityName: string;
    activityType: Actividad;
    activityDescription: string;
    roleInActivity: RoleInActivity;
    mentorAccreditationByBlock: AccreditationByBlock[];
    menteeAccreditationByBlock: AccreditationByBlock[];
}

interface UpcomingActivitiesUserProps {
    onRefetch?: (refetch: () => void) => void;
}

interface EvaluateCounterpart {
    name: string;
    hasFeedback: boolean;
    isAccredited: boolean;
    id: number;
    availableTimeId: number;
}

const UpcomingActivitiesUser: React.FC<UpcomingActivitiesUserProps> = ({ onRefetch }) => {
    const dispatch = useDispatch();
    const userPersonalData = useSelector(selectPersonalData);
    const userId = userPersonalData?.Id;
    const userEmail = userPersonalData?.Email;

    const [reload, setReload] = useState(false);
    const [selectedActivityId, setSelectedActivityId] = useState<number | null>(null);
    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [isMentor, setIsMentor] = useState(false);
    const [actividad, setActividad] = useState<Actividad | null>(null);
    const [contrapartes, setContrapartes] = useState<string[]>([]);
    const [selectedRole, setSelectedRole] = useState<'Mentor' | 'Mentee' | 'General' | null>(null);
    const [counterpartsListState, setCounterpartsListState] = useState<EvaluateCounterpart[]>([]);
    const [openEvaluateCounterpartsDialog, setOpenEvaluateCounterpartsDialog] = useState(false);
    const [currentCounterpart, setCurrentCounterpart] = useState<string>("");
    const [counterpartIds, setCounterpartIds] = useState<number[]>([]);
    const [availableTimeIds, setAvailableTimeIds] = useState<number[]>([]);

    const {
        data: activitiesData,
        isLoading: isLoadingActivities,
        refetch,
        error: activitiesError,
    } = useGetUpcomingActivitiesByUserQuery(userId ?? 0, {
        skip: userId === undefined || userId === null,
    });

    const activities: Activity[] = Array.isArray(activitiesData)
        ? activitiesData
        : (activitiesData?.activities ?? []);

    const [cancelUpcomingActivity] = useCancelUpcomingActivityByUserMutation();
    const [cancelMenteeContactWheel] = useCancelMenteeContactWheelMutation();
    const [cancelMentorContactWheel] = useCancelMentorContactWheelMutation();

    const statusNames: Record<number, string> = {
        1: "Acreditado",
        2: "Cancelado",
        3: "Sin confirmar",
        4: "Acreditado",
        5: "Evaluado"
    };

    useEffect(() => {
        if (reload) {
            refetch();
            setReload(false);
        }
    }, [reload, refetch]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        if (onRefetch) {
            onRefetch(refetch);
        }
    }, [onRefetch, refetch]);

    const handleEvaluation = (
        activityId: number,
        counterpartsList: EvaluateCounterpart[],
        isMentorRole: boolean,
        actividadType: Actividad
    ) => {
        setSelectedActivityId(activityId);
        setIsMentor(isMentorRole);
        setActividad(actividadType);
        setContrapartes(counterpartsList.map(cp => cp.name));
        setCounterpartIds(counterpartsList.map(cp => cp.id));

        const availableTimes = counterpartsList.map(cp => cp.availableTimeId).filter(id => id !== undefined);
        setAvailableTimeIds(availableTimes);

        setCounterpartsListState(counterpartsList);

        if (actividadType === 'Rueda de Contactos') {
            setOpenEvaluateCounterpartsDialog(true);
        } else {
            if (counterpartsList.length > 0) {
                setCurrentCounterpart(counterpartsList[0].name);
                setOpenFeedbackDialog(true);
            }
        }
    };

    const handleCancel = (id: number, role: 'Mentor' | 'Mentee' | 'General') => {
        setSelectedActivityId(id);
        setSelectedRole(role);
        setOpenConfirm(true);
    };

    const confirmCancel = async () => {
        if (selectedActivityId === null || selectedRole === null) return;

        const activity = activities.find((act) => act.activityId === selectedActivityId);

        if (!activity) {
            dispatch(deleteAlert({ alert_id: `canceling_activity_${Date.now()}` }));
            const error_alert = {
                id: `activity_not_found_${Date.now()}`,
                type: "error",
                title: "Error",
                desc: "Actividad no encontrada.",
                close: true,
                timeout: 5000,
            };
            dispatch(addAlert({ alert: error_alert }));
            setOpenConfirm(false);
            setSelectedActivityId(null);
            setSelectedRole(null);
            return;
        }

        if (!userEmail) {
            dispatch(deleteAlert({ alert_id: `canceling_activity_${Date.now()}` }));
            const error_alert = {
                id: `user_email_missing_${Date.now()}`,
                type: "error",
                title: "Error",
                desc: "No se encontró el correo electrónico del usuario.",
                close: true,
                timeout: 5000,
            };
            dispatch(addAlert({ alert: error_alert }));
            setOpenConfirm(false);
            setSelectedActivityId(null);
            setSelectedRole(null);
            return;
        }

        const new_alert = {
            id: `canceling_activity_${Date.now()}`,
            type: "loading",
            title: "Cancelando actividad",
            desc: "",
            close: false,
            timeout: 0,
        };
        dispatch(addAlert({ alert: new_alert }));

        try {
            if (activity.typeId === 6) {
                if (selectedRole === 'Mentor') {
                    await cancelMentorContactWheel({
                        email: userEmail,
                        activityId: activity.activityId,
                    }).unwrap();
                } else if (selectedRole === 'Mentee') {
                    await cancelMenteeContactWheel({
                        email: userEmail,
                        activityId: activity.activityId,
                    }).unwrap();
                }
            } else {
                await cancelUpcomingActivity({
                    activityId: activity.availableTimeId,
                    userId: userId ?? 0,
                }).unwrap();
            }
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            const success_alert = {
                id: `activity_canceled_${Date.now()}`,
                type: "success",
                title: "Actividad cancelada",
                desc: "La actividad ha sido cancelada exitosamente",
                close: true,
                timeout: 5000,
            };
            dispatch(addAlert({ alert: success_alert }));
            setReload(true);
        } catch (error) {
            console.error('Error al cancelar la actividad:', error);
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            const error_alert = {
                id: `cancel_activity_error_${Date.now()}`,
                type: "error",
                title: "Error al cancelar",
                desc: "Hubo un error al cancelar la actividad",
                close: true,
                timeout: 5000,
            };
            dispatch(addAlert({ alert: error_alert }));
        } finally {
            setOpenConfirm(false);
            setSelectedActivityId(null);
            setSelectedRole(null);
        }
    };

    const generateActionButtons = (activity: Activity): ActionButtonProps<any>[] => {
        const actionButtons: ActionButtonProps<any>[] = [];
        const { roleInActivity, typeId, mentorAccreditationByBlock, menteeAccreditationByBlock } = activity;
        const isRuedaDeContactos = typeId === 6;

        const userAcreditado = roleInActivity.statusId === 1;

        if (!userAcreditado) {
            return actionButtons;
        }

        if (isRuedaDeContactos) {
            if (roleInActivity.role !== 'Mentor') {
                return actionButtons;
            }

            const counterpartsList = menteeAccreditationByBlock.map(cp => {
                console.log('cp');
                console.log(cp);
                const hasFeedbackEntry = roleInActivity.hasFeedback.find(fb => fb.userId === cp.userId);
                const hasFeedback = hasFeedbackEntry ? hasFeedbackEntry.value : false;
                return {
                    name: cp.fullName,
                    hasFeedback: hasFeedback,
                    id: cp.userId,
                    isAccredited: cp.statusId === 1,
                    availableTimeId: cp.availableTimeId[0] || activity.availableTimeId[0],
                };
            });

            const hasAccreditedCounterpart = counterpartsList.some(cp => cp.isAccredited);
            console.log('hasAccreditedCounterpart');
            console.log(hasAccreditedCounterpart);
            const allAccreditedHaveFeedback = counterpartsList
                .every(cp => cp.hasFeedback);

            console.log('allAccreditedHaveFeedback');
            console.log(allAccreditedHaveFeedback);

            if (hasAccreditedCounterpart) {
                if (allAccreditedHaveFeedback) {

                    actionButtons.push({
                        label: 'Evaluado',
                        color: 'success',
                        onClick: () => { },
                        disabled: true,
                    });
                } else {
                    actionButtons.push({
                        label: 'Evaluar',
                        color: 'primary',
                        onClick: () => handleEvaluation(
                            activity.activityId,
                            counterpartsList,
                            roleInActivity.role === 'Mentor',
                            activity.activityType
                        ),
                        disabled: false,
                    });
                }
            } else {
                actionButtons.push({
                    label: 'Evaluar',
                    color: 'primary',
                    onClick: () => { },
                    disabled: true,
                });
            }
        } else {

            const counterpartAccreditationList = roleInActivity.role === 'Mentor' ? menteeAccreditationByBlock : mentorAccreditationByBlock;

            const counterpart = counterpartAccreditationList.find(cp => cp.statusId === 1);

            if (counterpart) {
                const hasFeedbackEntry = roleInActivity.hasFeedback.find(fb => fb.userId === counterpart.userId);
                const hasFeedback = hasFeedbackEntry ? hasFeedbackEntry.value : false;

                if (!hasFeedback) {
                    actionButtons.push({
                        label: 'Evaluar',
                        color: 'primary',
                        onClick: () => handleEvaluation(
                            activity.activityId,
                            [{
                                name: counterpart.fullName || "la contraparte",
                                hasFeedback: hasFeedback,
                                id: counterpart.userId || 0,
                                isAccredited: counterpart.statusId === 1,
                                availableTimeId: hasFeedbackEntry?.availableTimeId || activity.availableTimeId[0],
                            }],
                            roleInActivity.role === 'Mentor',
                            activity.activityType
                        ),
                        disabled: false,
                    });
                } else {

                    actionButtons.push({
                        label: 'Evaluado',
                        color: 'success',
                        onClick: () => { },
                        disabled: true,
                    });
                }
            } else {
                actionButtons.push({
                    label: 'Evaluar',
                    color: 'primary',
                    onClick: () => { },
                    disabled: true,
                });
            }
        }

        return actionButtons;
    };

    const formattedActivities = activities
        .filter((activity) => activity.activityId !== null)
        .map((activity) => {
            const isMentorRole = activity.roleInActivity.role === 'Mentor';
            const counterpart = isMentorRole ? activity.menteeAccreditationByBlock : activity.mentorAccreditationByBlock;

            return {
                Evento: activity.eventName,
                Temática: activity.activityType,
                Actividad: activity.activityName,
                Fecha: moment(activity.startDate).format('DD/MM/YYYY'),
                "Hora de Inicio": moment(activity.startDate).format('HH:mm'),
                "Hora de Termino": moment(activity.endDate).format('HH:mm'),
                Contraparte: counterpart.map((user) => ({
                    id: user.userId,
                    name: user.fullName,
                    hasFeedback: Array.isArray(user.hasFeedback) ? user.hasFeedback.some(fb => fb.value) : false,
                    isAccredited: user.statusId === 1
                })),
                Estado: activity.roleInActivity.statusId !== null ? (statusNames[activity.roleInActivity.statusId] || activity.roleInActivity.statusName) : activity.roleInActivity.statusName,
                Rol: activity.roleInActivity.role === 'Mentor' ? 'Experto' : 'Emprendedor',
                actionButtons: generateActionButtons(activity),
            };
        });

    const columnsToShow = [
        'Evento',
        'Actividad',
        'Temática',
        'Rol',
        'Fecha',
        'Hora de Inicio',
        'Hora de Termino',
        'Contraparte',
        'Estado',
    ];

    return isLoadingActivities ? (
        <div className='table-container-loading'>
            <p>Cargando actividades...</p>
        </div>
    ) : activitiesError ? (
        <div className='table-container'>
            <p>No hay actividades disponibles.</p>
        </div>
    ) : (
        <div className='card-container' style={{ width: '100%', paddingBottom: '80px', minHeight: '500px' }}>
            <GenericCardList
                columns={columnsToShow}
                data={formattedActivities}
                renderers={{
                    Contraparte: (contraparte: any) => (
                        <ul>
                            {contraparte.map((user: any, index: number) =>
                                user.id && user.name ? (
                                    <li key={index}>
                                        <a
                                            href={`/user-profile/${user.id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {user.name}
                                        </a>

                                    </li>
                                ) : (
                                    <li key={index}>
                                        <span>Información de usuario no disponible</span>
                                    </li>
                                )
                            )}
                        </ul>
                    )
                }}
            />
            <FeedbackDialog
                open={openFeedbackDialog}
                onClose={() => setOpenFeedbackDialog(false)}
                counterpartName={currentCounterpart}
                isMentor={isMentor}
                actividad={actividad as Actividad}
                contrapartes={[currentCounterpart]}
                availableTimeIds={availableTimeIds}
                counterpartIds={counterpartIds}
                refetch={refetch}
            />
            {actividad === 'Rueda de Contactos' && (
                <EvaluateCounterpartsDialog
                    open={openEvaluateCounterpartsDialog}
                    onClose={() => setOpenEvaluateCounterpartsDialog(false)}
                    counterparts={counterpartsListState.map(cp => ({
                        name: cp.name,
                        hasFeedback: cp.hasFeedback,
                        isAccredited: cp.isAccredited,
                        id: cp.id
                    }))}
                    onEvaluate={(name: string, id: number) => {
                        const selectedCp = counterpartsListState.find(cp => cp.id === id);
                        setCurrentCounterpart(name);
                        setCounterpartIds([id]);
                        setAvailableTimeIds([selectedCp?.availableTimeId || 0]);
                        setOpenEvaluateCounterpartsDialog(false);
                        setOpenFeedbackDialog(true);
                    }}
                />
            )}
        </div>
    );
};

export default UpcomingActivitiesUser;
