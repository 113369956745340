
import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
} from '@mui/material';
import { Formik, Form, Field, FieldProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectPersonalData } from '../../../redux/features/user/userSlice';
import { addAlert, deleteAlert } from '../../../redux/features/alerts/alertsSlice';
import { useNewSendActivityFeedbackMutation } from '../../../redux/features/events/eventsApiSlice';
import { useFeedbackQuestions } from '../FeedbackQuestions/useFeedbackQuestions';
import * as Yup from 'yup';
import RatingSection from './RatingSection';
import ContactToggle from './ContactToggle';
import { ROLES, Actividad } from '../FeedbackQuestions/constants';
import { Question } from '../FeedbackQuestions/feedbackQuestionsData';
import './FeedbackDialog.scss';

interface FeedbackDialogProps {
    open: boolean;
    onClose: () => void;
    counterpartName: string;
    isMentor: boolean;
    actividad: Actividad;
    contrapartes?: string[];
    availableTimeIds?: number[];
    counterpartIds?: number[];
    refetch: () => void;
}

interface FeedbackFormValues {
    [key: string]: any;
}

const FeedbackDialog: React.FC<FeedbackDialogProps> = (props) => {
    const {
        open,
        onClose,
        counterpartName,
        isMentor,
        actividad,
        contrapartes = [],
        availableTimeIds,
        counterpartIds,
        refetch
    } = props;

    const dispatch = useDispatch();
    const userPersonalData = useSelector(selectPersonalData);
    const userId = userPersonalData?.Id;

    const { questions } = useFeedbackQuestions(isMentor ? ROLES.EXPERTO : ROLES.EMPRENDEDOR, actividad);
    const [newSendActivityFeedback] = useNewSendActivityFeedbackMutation();

    const feedbackMappings: Record<string, Record<string, string>> = {
        [`${ROLES.EXPERTO}_Rueda de Contactos`]: {
            evaluarPitch: 'questionOne',
            potencialEscalamiento: 'questionTwo',
            interesNuevoContacto: 'keepContact',
        },
        [`${ROLES.EXPERTO}_Classic Car Pitch`]: {
            puntosClave: 'questionOne',
            satisfaccionGeneral: 'questionTwo',
            mantenerContacto: 'keepContact',
        },
        [`${ROLES.EXPERTO}_Mentoría`]: {
            puntosClave: 'questionOne',
            satisfaccionGeneral: 'questionTwo',
            mantenerContacto: 'keepContact',
        },
        [`${ROLES.EMPRENDEDOR}_Rueda de Contactos`]: {
        },
        [`${ROLES.EMPRENDEDOR}_Classic Car Pitch`]: {
            informacionUtil: 'questionOne',
            utilidadActividad: 'questionTwo',
            recomendarActividad: 'questionThree',
            mantenerContacto: 'keepContact',
        },
        [`${ROLES.EMPRENDEDOR}_Mentoría`]: {
            informacionUtil: 'questionOne',
            utilidadActividad: 'questionTwo',
            recomendarActividad: 'questionThree',
            mantenerContacto: 'keepContact',
        },
        [`${ROLES.EMPRENDEDOR}_Electric Car Pitch`]: {
            eleccionExperto: 'questionOne',
            utilidadActividad: 'questionTwo',
            recomendarActividad: 'questionThree',
            mantenerContacto: 'keepContact',
        },
        [`${ROLES.EXPERTO}_Electric Car Pitch`]: {
            puntosClave: 'questionOne',
            eleccionEmprendedor: 'questionTwo',
            satisfaccionGeneral: 'questionThree',
            mantenerContacto: 'keepContact',
        },
        [`${ROLES.EMPRENDEDOR}_Wheel Pitch`]: {
            eleccionExperto: 'questionOne',
            utilidadActividad: 'questionTwo',
            recomendarActividad: 'questionThree',
            mantenerContacto: 'keepContact',
        },
        [`${ROLES.EXPERTO}_Wheel Pitch`]: {
            puntosClave: 'questionOne',
            eleccionEmprendedor: 'questionTwo',
            satisfaccionGeneral: 'questionThree',
            mantenerContacto: 'keepContact',
        },
        [`${ROLES.EMPRENDEDOR}_Elevator Pitch`]: {
            eleccionExperto: 'questionOne',
            utilidadActividad: 'questionTwo',
            recomendarActividad: 'questionThree',
            mantenerContacto: 'keepContact',
        },
        [`${ROLES.EXPERTO}_Elevator Pitch`]: {
            puntosClave: 'questionOne',
            eleccionEmprendedor: 'questionTwo',
            satisfaccionGeneral: 'questionThree',
            mantenerContacto: 'keepContact',
        },
    };


    const getMappingKey = (): string => `${isMentor ? ROLES.EXPERTO : ROLES.EMPRENDEDOR}_${actividad}`;

    const applyMapping = (feedback: Record<string, any>): Record<string, any> => {
        const mapping = feedbackMappings[getMappingKey()];
        if (!mapping) {
            return feedback;
        }
        const standardizedFeedback: Record<string, any> = {};
        Object.entries(feedback).forEach(([key, value]) => {
            const standardizedKey = mapping[key] || key;
            standardizedFeedback[standardizedKey] = value;
        });
        return standardizedFeedback;
    };

    const generateValidationSchema = (questions: Question[]): Yup.ObjectSchema<any> => {
        const shape: { [key: string]: Yup.AnySchema } = {};

        questions.forEach((question) => {
            if (question.type === 'rating') {
                shape[question.name] = Yup.number()
                    .min(1, 'Mínimo 1 estrella')
                    .max(5, 'Máximo 5 estrellas')
                    .required('Este campo es obligatorio');
            } else if (question.type === 'toggle') {
                shape[question.name] = Yup.boolean().required('Este campo es obligatorio');
            }
        });

        return Yup.object().shape(shape);
    };

    const validationSchema = generateValidationSchema(questions);

    const generateInitialValues = (questions: Question[]): FeedbackFormValues => {
        const initialValues: FeedbackFormValues = {};

        questions.forEach((question) => {
            if (question.type === 'rating') {
                initialValues[question.name] = question.defaultValue ?? 0;
            } else if (question.type === 'toggle') {
                initialValues[question.name] = question.defaultValue ?? false;
            }
        });

        return initialValues;
    };

    const initialValues = generateInitialValues(questions);

    const handleComplete = async (feedback: FeedbackFormValues) => {
        const standardizedFeedbacks = applyMapping(feedback);

        const dataToSend = {
            AvailableTimeIds: availableTimeIds ?? [],
            ...standardizedFeedbacks,
            isMentor,
            CounterpartUserId: counterpartIds?.[0] ?? 0,
            userid: userId,
        };

        const new_alert = {
            id: `sending_feedback_${Date.now()}`,
            type: "loading",
            title: "Enviando feedback",
            desc: "",
            close: false,
            timeout: 0,
        };
        dispatch(addAlert({ alert: new_alert }));

        try {
            const response = await newSendActivityFeedback(dataToSend).unwrap();
            dispatch(deleteAlert({ alert_id: new_alert.id }));

            const success_alert = {
                id: `feedback_sent_${Date.now()}`,
                type: "success",
                title: "Feedback enviado",
                desc: "El feedback ha sido enviado exitosamente",
                close: true,
                timeout: 5000,
            };
            dispatch(addAlert({ alert: success_alert }));

            onClose();
            refetch();
        } catch (error) {
            console.error('Error al enviar los datos al endpoint:', error);
            dispatch(deleteAlert({ alert_id: new_alert.id }));

            const error_alert = {
                id: `feedback_error_${Date.now()}`,
                type: "error",
                title: "Error al enviar feedback",
                desc: "Hubo un error al enviar el feedback",
                close: true,
                timeout: 5000,
            };
            dispatch(addAlert({ alert: error_alert }));
        }
    };

    const dialogTitle = `Feedback para "${counterpartName}"`;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <Formik<FeedbackFormValues>
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        handleComplete(values);
                    }}
                >
                    {({ isSubmitting, setFieldValue, errors, touched }) => (
                        <Form noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                {questions.map((question) => (
                                    <Grid item xs={12} key={question.name}>
                                        {question.type === 'rating' ? (
                                            <Field name={question.name}>
                                                {({ field }: FieldProps) => (
                                                    <RatingSection
                                                        question={question}
                                                        value={field.value || 0}
                                                        onChange={(value: number) => {
                                                            setFieldValue(field.name, value);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        ) : (
                                            <Field name={question.name}>
                                                {({ field }: FieldProps) => (
                                                    <ContactToggle
                                                        maintainContact={field.value}
                                                        onToggle={(val: boolean) => {
                                                            setFieldValue(field.name, val);
                                                        }}
                                                        counterpartName={counterpartName}
                                                    />
                                                )}
                                            </Field>
                                        )}
                                        {errors[question.name] &&
                                            touched[question.name] &&
                                            typeof errors[question.name] === 'string' && (
                                                <div className="error-message">{String(errors[question.name])}</div>
                                            )}
                                    </Grid>
                                ))}
                            </Grid>
                            <DialogActions>
                                <Button onClick={onClose} className="cancel-button">
                                    CANCELAR
                                </Button>
                                <Button type="submit" className="send-button" disabled={isSubmitting}>
                                    ENVIAR
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default FeedbackDialog;
