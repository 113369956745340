// src/pages/UserProfileExternal/UserProfileExternal.tsx

import './UserProfileExternal.scss';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { personalData } from '../../interfaces/dimentions';
import { Dialog, DialogContent, DialogTitle, DialogActions, useMediaQuery, useTheme, Button } from '@mui/material';
import FormularioSolicitarReunion from './FormularioSolicitarReunion';
import UserProfileExternalTopCard from './UserProfileExternalTopCard';
import { useGetUserStartupsMutation } from '../../redux/features/startups/startupsApiSlice';
import {
    useGetUserExpertisesMutation,
    useGetUserLanguagesMutation,
    useGetUserPublicDataMutation,
    useGetUserRolesMutation,
    useGetUserTagsMutation
} from '../../redux/features/user/userApiSlice';
import UserProfileExternalStartups from './UserProfileExternalStartups';
import UserProfileExternalExperiencias from './UserProfileExternalExperiencias';
import UserProfileExternalIdiomasTags from './UserProfileExternalIdiomasTags';
import BottomNav from '../../components/Navegation/BottomNav/BottomNav';
import { usePostAddUserContactMutation } from '../../redux/features/user/userApiSlice';
import { useGetActivitiesByIdsFeedbackQuery, useNewSendActivityFeedbackMutation } from '../../redux/features/events/eventsApiSlice';
import GetUserDonatedTime from '../../hooks/meetings/GetUserDonatedTime/GetUserDonatedTime';
import FormularioSolicitarTiempo from './FormularioSolicitarTiempo';
import { useGetUserFeedbacksMutation } from '../../redux/features/meetings/meetingsApiSlice';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';
import { DialogTiempoReunion } from './DialogTiempoReunion';
import FeedbackDialog from '../Events/FeedbackDialog/FeedbackDialog';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import { ACTIVITIES, Actividad } from '../Events/FeedbackQuestions/constants';

// Definición de tipos e interfaces
interface UserProfileExternalProps {
    user_id_param?: number;
    handleClickCloseDialogPublicProfile?: () => void;
    handleLoadContactsMain?: () => Promise<void>;
}

interface HasFeedbackItem {
    availableTimeId: number;
    userId: number;
    value: boolean;
}

interface AccreditationByBlock {
    availableTimeId: number[];
    activityId: number;
    userId: number;
    fullName: string;
    statusId: number | null;
    statusName: string;
    hasFeedback: HasFeedbackItem[];
    counterpart?: boolean;
}

interface RoleInActivity {
    availableTimeId: number[];
    activityId: number;
    userId: number;
    fullName: string;
    role: 'Mentor' | 'Mentee';
    statusId: number | null;
    statusName: string;
    hasFeedback: HasFeedbackItem[];
}

interface FeedbackData {
    availableTimeId: number[];
    activityId: number;
    typeId: number;
    statusId: number;
    statusName: string;
    startDate: string;
    endDate: string;
    eventName: string;
    activityName: string;
    activityType: Actividad;
    activityDescription: string;
    roleInActivity: RoleInActivity;
    mentorAccreditationByBlock: AccreditationByBlock[];
    menteeAccreditationByBlock: AccreditationByBlock[];
}

function useQuery() {
    const { search } = useLocation();
    return new URLSearchParams(search);
}

const UserProfileExternal = ({ user_id_param, handleClickCloseDialogPublicProfile, handleLoadContactsMain }: any) => {
    const params: any = useParams();
    const query = useQuery(); // ProfileQR
    const mode = query.get('mode');  // ProfileQR
    const textPublicProfile = useSelector(selectCurrentDictonary).public_profile;
    const navigate = useNavigate(); // Hook para navegación
    const dispatch = useDispatch(); // Inicializar useDispatch

    const user_personal_data: personalData = useSelector(selectPersonalData); // acceder a datos del usuario logueado
    const user_id = user_personal_data?.Id || 0;

    let default_user = -1;
    let isExternal = 0;
    let isModal = 0;
    let clase_external = "";

    const [isModalQROpen, setIsModalQROpen] = useState(false);
    const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);

    const [sendActivityFeedback] = useNewSendActivityFeedbackMutation();  // Mutación de feedback

    const [actividad, setActividad] = useState<Actividad | null>(null);

    // Determinar el usuario por defecto y el modo externo o interno
    if (!params.userid || parseInt(params.userid) === user_id) { // no hay parametros en la ruta o es el propio usuario
        if (user_id_param && user_id_param > 0) { // perfil publico dentro de modal
            default_user = user_id_param;
            isExternal = 1;
            isModal = 1;
            clase_external = "interno";
        } else { // perfil personal
            default_user = user_id;
            isExternal = 0;
            clase_external = "interno";
        }
    } else { // hay parametros en la ruta y es otro usuario
        isExternal = 1;
        isModal = 0;
        clase_external = "externo body-container";
        default_user = parseInt(params.userid);
    }
    const user_id_profile: number = default_user;

    /* -------------- SOLICITUDES DE DATOS ---------------------------------------------------------------------- */
    const [getUserStartups, { isLoading: isLoadingGetUserStartups }] = useGetUserStartupsMutation();
    const [user_startups, setUserStartups] = useState<any[]>([]);

    const fetchUserStartups = async (user_id: number) => {
        try {
            const res: any = await getUserStartups({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            setUserStartups(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    const [addUserContact] = usePostAddUserContactMutation();

    const handleAddContact = async () => {
        try {
            const userId = user_personal_data.Id;
            const contactUserId = user_id_profile;
            await addUserContact({ userId, contactUserId }).unwrap();
            setIsModalQROpen(false);
        } catch (error) {
            console.error('Error al añadir contacto', error);
        }
    };

    /* Experiencia laboral */
    const [getUserExpertises, { isLoading: isLoadingGetUserExpertises }] = useGetUserExpertisesMutation();
    const [user_expertises, setUserExpertises] = useState<any[]>([]);

    const fetchUserExpertises = async (user_id: number) => {
        try {
            const res: any = await getUserExpertises({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            setUserExpertises(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    /* Datos públicos */
    const [getUserPublicData, { isLoading: isLoadingGetUserPublicData }] = useGetUserPublicDataMutation();
    const [user_public_data, setUserPublicData] = useState<any>({});

    const fetchUserPublicData = async (user_id: number) => {
        try {
            const res: any = await getUserPublicData({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            setUserPublicData(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    /* Tags */
    const [getUserTags, { isLoading: isLoadingGetUserTags }] = useGetUserTagsMutation();
    const [user_tags, setUserTags] = useState<any>({});

    const fetchUserTags = async (user_id: number) => {
        try {
            const res: any = await getUserTags({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            setUserTags(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    /* Idiomas */
    const [getUserLanguages, { isLoading: isLoadingGetUserLanguages }] = useGetUserLanguagesMutation();
    const [user_languages, setUserLanguages] = useState<any>({});

    const fetchUserLanguages = async (user_id: number) => {
        try {
            const res: any = await getUserLanguages({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            setUserLanguages(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    /* Roles */
    const [getUserRoles, { isLoading: isLoadingGetUserRoles }] = useGetUserRolesMutation();
    const [user_roles, setUserRoles] = useState<any[]>([]);
    const [esEmprendedor, setEsEmprendedor] = useState<boolean>(false);
    const [esExperto, setEsExperto] = useState<boolean>(false);

    const fetchRoles = async (user_id: number) => {
        try {
            const res: any = await getUserRoles({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            let arr: any[] = [];
            resdata.forEach((element: any) => {
                if (element.Active === 1) {
                    if (element.RolId === 1) { // Emprendedor
                        setEsEmprendedor(true);
                        arr.push({ name: "Emprendedor", id: element.RolId });
                    }
                    if (element.RolId === 6) { // Experto
                        setEsExperto(true);
                        arr.push({ name: "Experto", id: element.RolId });
                    }
                }
            });
            setUserRoles(arr);
        } catch (error) {
            console.log(error);
        }
    };

    /* Feedbacks */
    const [getUserFeedbacks, { isLoading: isLoadingGetUserFeedbacks }] = useGetUserFeedbacksMutation();
    const [user_feedbacks, setUserFeedbacks] = useState<any[]>([]);
    const [user_feedbacks_prom_estrellas, setUserFeedbacksPromEstrellas] = useState<number | null>(null);

    const fetchUserFeedbacks = async (user_id: number) => {
        setUserFeedbacksPromEstrellas(null);
        try {
            const res: any = await getUserFeedbacks({ user_id: user_id });
            const resdata: any[] = JSON.parse(res.data.responseData);
            if (resdata && resdata.length > 0) {
                let suma = 0;
                let cont_n = 0;

                resdata.forEach((element: any) => {
                    cont_n += 1;
                    suma += element.UserEvaluationStars;
                });
                setUserFeedbacksPromEstrellas(suma / cont_n);
            }

            setUserFeedbacks(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    /* -------------- FIN SOLICITUDES DE DATOS ---------------------------------------------------------------------- */

    /* -------------- USEEFFECT ---------------------------------------------------------------------- */
    useEffect(() => {
        fetchUserStartups(user_id_profile).catch(console.error);
        fetchUserExpertises(user_id_profile).catch(console.error);
        fetchUserPublicData(user_id_profile).catch(console.error);
        fetchUserTags(user_id_profile).catch(console.error);
        fetchUserLanguages(user_id_profile).catch(console.error);
        fetchRoles(user_id_profile).catch(console.error);
        fetchUserFeedbacks(user_id_profile).catch(console.error);
    }, [params, user_id_profile]); // Asegurarse de incluir user_id_profile en las dependencias

    /* Llamada a la API para obtener los datos de feedback */
    const { data: feedbackData, isFetching: isFetchingFeedbackData, error } = useGetActivitiesByIdsFeedbackQuery(
        {
            id1: user_id,
            id2: parseInt(params.userid)
        },
        {
            skip: !(mode === "QR" && params.userid)  // Evitar el fetch si no se cumplen las condiciones
        }
    );

    console.log(feedbackData);

    const [isMentor, setIsMentor] = useState(false);
    const [isMentee, setIsMentee] = useState(false);

    useEffect(() => {
        if (mode === "QR" && !isFetchingFeedbackData && feedbackData && feedbackData.roleInActivity) {
            console.log("Modo QR activo, datos de feedback obtenidos:", feedbackData);

            const { roleInActivity, activityType } = feedbackData;

            // Validar que activityType es válido
            const validActivityType = Object.values(ACTIVITIES).includes(activityType)
                ? activityType
                : ACTIVITIES.MENTORIA; // Valor por defecto

            setActividad(validActivityType);

            // Determinar el rol del usuario logueado
            if (roleInActivity.userId === user_id) {
                if (roleInActivity.role === 'Mentor') {
                    console.log("Usuario es Mentor");
                    setIsMentor(true);
                    setIsMentee(false);
                } else if (roleInActivity.role === 'Mentee') {
                    console.log("Usuario es Mentee");
                    setIsMentor(false);
                    setIsMentee(true);
                } else {
                    console.log("Usuario no tiene un rol definido");
                    setIsMentor(false);
                    setIsMentee(false);
                }

                // **Inicio de la lógica actualizada para mostrar FeedbackDialog**
                // Verificar si ya se dio feedback
                const hasGivenFeedback = roleInActivity.hasFeedback && roleInActivity.hasFeedback.some((fb: HasFeedbackItem) => fb.value === true);

                // Nueva validación: 
                // - Si la actividad es de tipo 6, solo los mentores pueden dar feedback
                // - Para otros tipos, cualquier usuario (Mentor o Mentee) que no haya dado feedback puede ver el diálogo
                if (activityType === 6) { // Validación directa según tu indicación
                    if (isMentor && !hasGivenFeedback) {
                        console.log("El mentor puede dar feedback para actividad de tipo 6");
                        setShowFeedbackDialog(true);
                    } else {
                        console.log("Restricción aplicada: solo mentores pueden dar feedback para actividad de tipo 6, o ya se dio feedback");
                        setShowFeedbackDialog(false); // **Asegurar que el diálogo esté cerrado**
                    }
                } else {
                    if (!hasGivenFeedback) {
                        console.log("El usuario aún no ha dado feedback");
                        setShowFeedbackDialog(true);
                    } else {
                        console.log("El usuario ya ha dado feedback");
                        setShowFeedbackDialog(false); // **Asegurar que el diálogo esté cerrado**
                    }
                }
                // **Fin de la lógica actualizada**
            } else {
                console.log("El roleInActivity no corresponde al usuario actual");
                setShowFeedbackDialog(false); // **Asegurar que el diálogo esté cerrado**
            }
        } else {
            console.log("No se cumplen las condiciones para mostrar FeedbackDialog:", {
                mode,
                isFetchingFeedbackData,
                feedbackData
            });
            setShowFeedbackDialog(false); // **Asegurar que el diálogo esté cerrado**
        }
    }, [mode, user_id_profile, feedbackData, isFetchingFeedbackData, isMentor, isMentee]); // Añadido isMentor e isMentee a las dependencias

    /* -------------- DIALOGOS SOLICITAR TIEMPO Y REUNION ---------------------------------------------------------------------- */
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const [sol_reu_open, set_sol_reu_open] = useState(false);
    function handleClickDialog() {
        set_sol_reu_open(!sol_reu_open);
    }

    /* Manejo de carga */
    const isLoading = isLoadingGetUserStartups || isLoadingGetUserExpertises || isLoadingGetUserPublicData ||
        isLoadingGetUserTags || isLoadingGetUserLanguages || isLoadingGetUserRoles || isLoadingGetUserFeedbacks;

    /* HTML de perfil público */
    const public_profile_html = () => {
        if (isLoading) {
            let fullscreenClass = "";
            if (isModal === 0) {
                fullscreenClass = "fullscreen";
            }
            return (
                <div className={'loading-backdrop ' + fullscreenClass}>
                    <h2>
                        <i className="fas fa-spinner fa-spin mr-4"></i>
                        <span className='d-inline'>{textPublicProfile.label_loading}</span>
                    </h2>
                </div>
            );
        } else {
            return (
                <div className={'user-profile-container ' + clase_external}>
                    <div className='row no-gutters'>
                        <div className='col-12'>
                            <UserProfileExternalTopCard
                                user_feedbacks_prom_estrellas={user_feedbacks_prom_estrellas}
                                user_id_profile={user_id_profile}
                                esEmprendedor={esEmprendedor}
                                esExperto={esExperto}
                                user_expertises={user_expertises}
                                user_donated_times={[]}
                                isModal={isModal}
                                handleClickCloseDialogPublicProfile={handleClickCloseDialogPublicProfile}
                                user_public_data={user_public_data}
                                is_loading_public_data={isLoadingGetUserPublicData}
                                handleClickDialog={handleClickDialog}
                                isExternal={isExternal}
                                handleAddContact={handleAddContact}
                                handleLoadContactsMain={handleLoadContactsMain}
                                currentUrl={window.location.href}
                                isQRMode={mode === "QR"}
                            />
                        </div>
                        <div className='col-12'>
                            <UserProfileExternalIdiomasTags
                                user_languages={user_languages}
                                is_loading_user_languages={isLoadingGetUserLanguages}
                                user_tags={user_tags}
                            />
                        </div>
                    </div>
                    <div className='row no-gutters user-profile-startups-experiences-container'>
                        {esEmprendedor && (
                            <div className='col-12'>
                                <UserProfileExternalStartups
                                    user_startups={user_startups}
                                    is_loading_user_startups={isLoadingGetUserStartups}
                                />
                            </div>
                        )}
                        {esExperto && (
                            <div className='col-12'>
                                <UserProfileExternalExperiencias
                                    user_expertises={user_expertises}
                                    is_loading_user_expertises={isLoadingGetUserExpertises}
                                />
                            </div>
                        )}
                    </div>
                </div>
            );
        }
    };

    let navegation_html = <></>;
    if (isModal === 0) {
        navegation_html = <BottomNav />;
    }

    // Determinar el nombre y ID de la contraparte
    let counterpartName = '';
    let counterpartId = 0;

    if (feedbackData) {
        if (isMentor) {
            const mentee = feedbackData.menteeAccreditationByBlock.find(
                (acc: AccreditationByBlock) => acc.userId !== user_id
            );
            if (mentee) {
                counterpartName = mentee.fullName;
                counterpartId = mentee.userId;
            }
        } else if (isMentee) {
            const mentor = feedbackData.mentorAccreditationByBlock.find(
                (acc: AccreditationByBlock) => acc.userId !== user_id
            );
            if (mentor) {
                counterpartName = mentor.fullName;
                counterpartId = mentor.userId;
            }
        }
    }

    return (
        <>
            {navegation_html}
            {public_profile_html()}

            <DialogTiempoReunion
                user_id_profile={user_id_profile}
                sol_reu_open={sol_reu_open}
                handleClickDialog={handleClickDialog}
                textPublicProfile={textPublicProfile}
                QR={mode === "QR"}
            />

            {/* FeedbackDialog */}
            {showFeedbackDialog && feedbackData && feedbackData.roleInActivity && (
                <FeedbackDialog
                    open={showFeedbackDialog}
                    onClose={() => setShowFeedbackDialog(false)}
                    counterpartName={counterpartName}
                    isMentor={isMentor}
                    actividad={actividad as Actividad}
                    contrapartes={[counterpartName]}
                    availableTimeIds={feedbackData.availableTimeId}
                    counterpartIds={[counterpartId]}
                    refetch={() => { }}
                />
            )}

            {/* Manejo de errores de feedback */}
            {error && (
                <div className="error-message">
                    <p>{(error as any).data?.mensaje || 'Error al cargar los datos de feedback.'}</p>
                </div>
            )}
        </>
    );
}

export default UserProfileExternal;
