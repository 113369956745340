//import { useParams } from 'react-router-dom'
import "./UserPublicData.scss";
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux"

import { Formik, Form } from 'formik'
import { usePutUserPublicDataMutation } from '../../../redux/features/user/userApiSlice'
import { addAlert, deleteAlert } from '../../../redux/features/alerts/alertsSlice'
import TextAreaInput from "../../../components/Form/TextAreaInput";
import { setUserPublicData } from "../../../redux/features/user/userSlice";
import { selectCurrentDictonary } from "../../../redux/features/language/LanguageSlice";

const UserPublicDataDesc = ({ public_data, userId }: any) => {
    const dispatch = useDispatch()
    const textPublicData = useSelector(selectCurrentDictonary).public_data

    const [isLoading, setIsLoading] = useState(false)
    const [isValidated, setIsValidated] = useState(false)
    const [cant_caracteres, set_cant_caracteres] = useState(0)

    const [putUserPublicData, { isLoadingPutUserPublicData }]: any = usePutUserPublicDataMutation()

    let max_caracteres = 500;
    let initialValues = {
        "Description": (public_data.Description && public_data.Description !== "null" ) ? public_data.Description : "",
    }


    useEffect(() => {
    }, [public_data])


    const validate = (values: any) => {

        const errors: any = {}
        if (!values.Description) {
            set_cant_caracteres(0)
        } else {
            set_cant_caracteres(values.Description.length)
            if (values.Description.length > max_caracteres) {
                errors.Description = "maximo " + max_caracteres + " caracteres"
            }
            if (values.Description.length < 0) {
                errors.Description = "minimo 0 caracteres"
            }
        }

        if (!(Object.entries(errors).length > 0)) {
            setIsValidated(true)
        } else {
            setIsValidated(false)
        }
        return errors
    }


    const handleSubmit = async (values: any) => {

        setIsLoading(true)
        dispatch(deleteAlert({ alert_id: "error_user_desc" }))
        dispatch(deleteAlert({ alert_id: "cargando_user_desc" }))
        let new_alert = { id: "cargando_user_desc", type: "loading", title: textPublicData.titulo_7, desc: textPublicData.titulo_9, close: false }
        dispatch(addAlert({ alert: new_alert }))

        try {
            let body_aux = { ...public_data, Description: values.Description }

            let formData = new FormData();
            formData.append('Description', values.Description);

            formData.append('Name', public_data.Name);
            formData.append('LastName', public_data.LastName);
            formData.append('Instagram', public_data.Instagram);
            formData.append('Twitter', public_data.Twitter);
            formData.append('LinkedIn', public_data.LinkedIn);
            formData.append('Youtube', public_data.Youtube);
            formData.append('Facebook', public_data.Facebook);

            const res: any = await putUserPublicData({ user_id: userId, body: formData })
            const resCode: any = res.data.statusCode
            if (resCode == 200) {
                dispatch(deleteAlert({ alert_id: "cargando_user_desc" }))
                let new_alert = { type: "success", title: "Editar descripción", desc: "OK", timeout: 2000, close: true }
                dispatch(addAlert({ alert: new_alert }))
                dispatch(setUserPublicData({ user_public_data: body_aux }))

            } else {
                dispatch(deleteAlert({ alert_id: "cargando_user_desc" }))
                let new_alert2 = { id: "error_user_desc", type: "error", title: "Error en Editar descripción", desc: "", close: true, timeout: 50000 }
                dispatch(addAlert({ alert: new_alert2 }))
            }
        } catch (error) {
            dispatch(deleteAlert({ alert_id: "cargando_user_desc" }))
            let new_alert2 = { id: "error_user_desc", type: "error", title: "Error en Editar descripción", desc: "", close: true, timeout: 50000 }
            dispatch(addAlert({ alert: new_alert2 }))
        }
        setIsLoading(false)

    }

    let button_html = <></>
    if (isValidated) {
        button_html = <div className='col-12 mt-3'>
            <div className='d-flex justify-content-end'>
                <button type="submit" disabled={isLoading} className='btn btn-primary btn-rounded '>{textPublicData.btn_guardar}</button>
            </div>
        </div>
    } else {
        button_html = <div className='col-12 mt-3'>
            <div className='d-flex justify-content-end'>
                <button type="submit" disabled className='btn btn-primary btn-rounded '>{textPublicData.btn_guardar}</button>
            </div>
        </div>

    }

    let form_html = <>{textPublicData.titulo_7}</>
    if (public_data) {
        form_html = <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validate={validate}
            validateOnChange={true}
            onSubmit={values => handleSubmit(values)}
        >
            <Form>
                <div className='row no-gutters'>
                    <div className='col-12'>
                        <TextAreaInput className="w-100" name="Description" type="text" label="" showErrorText={true} defaultValue={initialValues.Description} />
                    </div>
                    {button_html}
                </div>
            </Form>
        </Formik>
    }

    let cant_caracteres_html = <></>
    if (cant_caracteres && cant_caracteres > 0 && isValidated) {
        cant_caracteres_html = <>({cant_caracteres}/{max_caracteres})</>
    }

    return (
        <div>
            <div className='d-flex justify-content-start mt-2 mb-3'>
                <div>
                    <h5><b>{textPublicData.titulo_3}</b></h5>
                    <p>Max {max_caracteres} caracteres {cant_caracteres_html}</p>

                </div>
            </div>
            <div className='row no-gutters '>
                <div className='col-12 mb-4'>
                    {form_html}
                </div>
            </div>
        </div>
    )
}

export default UserPublicDataDesc;