import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    Typography,
    Box,
    IconButton
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';

interface EvaluateCounterpartsDialogProps {
    open: boolean;
    onClose: () => void;
    counterparts: {
        name: string;
        hasFeedback: boolean;
        isAccredited: boolean;
        id: number;
    }[];
    onEvaluate: (name: string, id: number) => void;
}

const EvaluateCounterpartsDialog: React.FC<EvaluateCounterpartsDialogProps> = ({ open, onClose, counterparts, onEvaluate }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Selecciona una contraparte para evaluar</DialogTitle>
            <DialogContent>
                {counterparts.length === 0 ? (
                    <Typography>No hay contrapartes disponibles para evaluar.</Typography>
                ) : (
                    <List>
                        {counterparts.map((cp) => (
                            <ListItem key={cp.id} divider>
                                <ListItemText primary={cp.name} />
                                <Box display="flex" alignItems="center">
                                    {cp.isAccredited ? (
                                        cp.hasFeedback ? (
                                            <Box display="flex" alignItems="center">
                                                <CheckCircleIcon color="success" />
                                                <Typography variant="button" ml={1} color="success.main">
                                                    Evaluado
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Box
                                                onClick={() => onEvaluate(cp.name, cp.id)}
                                                aria-label={`Evaluar a ${cp.name}`}
                                                aria-pressed={cp.hasFeedback}
                                                display="flex"
                                                alignItems="center"
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                <EditIcon color="primary" />
                                                <Typography variant="button" ml={1} color="primary.main">
                                                    Editar
                                                </Typography>
                                            </Box>
                                        )
                                    ) : (
                                        <Box
                                            aria-label={`No evaluar a ${cp.name}`}
                                            display="flex"
                                            alignItems="center"
                                            sx={{ cursor: 'not-allowed', color: 'text.disabled' }}
                                        >
                                            <EditIcon color="disabled" />
                                            <Typography variant="button" ml={1} color="text.disabled">
                                                Editar
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </ListItem>
                        ))}
                    </List>


                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    CERRAR
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EvaluateCounterpartsDialog;
